import React from 'react';
import mainLogo from './assets/image/main_logo.png';
import btnImg1 from './assets/image/btn_img_1.jpeg';
import btnImg2 from './assets/image/btn_img_2.jpeg';
import btnImg3 from './assets/image/btn_img_3.jpeg';
import btnImg4 from './assets/image/btn_img_4.jpeg';
import btnImg5 from './assets/image/btn_img_5.jpeg';
import phoneIcon from './assets/image/phone.svg';

function App() {
    return (
        <div className="App">
            <div className={'nav_wrapper'}>
                <div style={{display: 'flex'}}>
                    <div className={'logo'}>
                        <img src={mainLogo} alt={'main_logo'}/>
                    </div>
                    <ul className={'nav_links'}>
                        <li>
                            <a href={'http://kcookart-edu.co.kr/2022/edu/cooking.asp'}>
                                조리
                            </a>
                        </li>
                        <li>
                            <a href={'http://kcookart-edu.co.kr/2022/edu/baking.asp'}>
                                제과제빵
                            </a>
                        </li>
                        <li>
                            <a href={'http://kcookart-edu.co.kr/2022/edu/barista.asp'}>
                                바리스타
                            </a>
                        </li>
                        <li>
                            <a href={'http://kcookart-edu.co.kr/2022/edu/oneday.asp'}>
                                원데이클래스
                            </a>
                        </li>
                        <li>
                            <a href={'http://kcookart-edu.co.kr/2022/edu/youngChef.asp'}>
                                입시&대회반
                            </a>
                        </li>
                    </ul>
                </div>
                <a href={'tel:02-525-6239'} className={'call_btn'}>
                    <img src={phoneIcon} alt={'phone_icon'}/>
                    <p>02-525-6239</p>

                    <em>365일 상담가능</em>
                </a>
            </div>
            <div className={'content_wrapper'}>
                <div className={'btn_list_wrapper'}>
                    <ul>
                        <li>
                            <a href={'http://kcookart-edu.co.kr/2022/edu/cooking.asp'}>
                                <img src={btnImg1} alt={'class_img'}/>
                                <div className={'btn_desc'}>
                                    <span>Cooking</span>
                                    <span>조리</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href={'http://kcookart-edu.co.kr/2022/edu/baking.asp'}>
                                <img src={btnImg3} alt={'class_img'}/>
                                <div className={'btn_desc'}>
                                    <span>Baking</span>
                                    <span>제과제빵</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href={'http://kcookart-edu.co.kr/2022/edu/barista.asp'}>
                                <img src={btnImg2} alt={'class_img'}/>
                                <div className={'btn_desc'}>
                                    <span>Barista</span>
                                    <span>바리스타</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href={'http://kcookart-edu.co.kr/2022/edu/oneday.asp'}>
                                <img src={btnImg4} alt={'class_img'}/>
                                <div className={'btn_desc'}>
                                    <span>One Day</span>
                                    <span>원데이클래스</span>
                                </div>
                            </a>
                        </li>
                        <li className={'mobile_last_btn'}>
                            <a href={'http://kcookart-edu.co.kr/2022/edu/youngChef.asp'}>
                                <img src={btnImg5} alt={'class_img'}/>
                                <div className={'btn_desc'}>
                                    <span>Entrance Exam & Competition</span>
                                    <span>입시&대회반</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default App;
